/**
 * External dependencies:
 */
import moment from "moment";
import $ from 'jquery';
import 'magnific-popup';

const defaultError = 'There was an error while submitting the email.';
const submittedKey = '_bdmMarketingSubmitted';
const declinedKey = '_bdmMarketingDeclined';

const getIsLoggedIn = () => window._bdmLoggedIn ?? false;
const getHasSubmitted = () => Boolean(localStorage.getItem(submittedKey));
const unsetHasDeclinedEmail = () => localStorage.removeItem(declinedKey);

const setHasDeclinedEmail = () => {
  const expire = moment().add(30, 'days').unix();

  localStorage.setItem(declinedKey, JSON.stringify({
    hasDeclined: true,
    expire,
  }));
};

const getHasDeclined = () => {
  let storageValue;
  try {
    storageValue = JSON.parse(localStorage.getItem(declinedKey));
  } catch (err) {
    return false;
  }

  const expire = storageValue?.expire;
  if (!expire) {
    return false;
  }

  const now = moment().unix();
  if (expire < now) {
    unsetHasDeclinedEmail();
    return false;
  }

  return true;
};

const initForm = () => {
  let loading = false;
  const $form = $('#email-marketing-popup form');
  const $content = $('#email-marketing-popup .content');
  const $success = $('#email-marketing-popup .success');
  const $loader = $content.find('.loader');

  const showSuccess = () => {
    $content.hide();
    $success.show();
  };

  const showError = (error) => {
    const $error = $form.find(".form-error");
    $error.text(error);
    $error.addClass("error");
  };

  $form.on('submit', function(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    loading = true;
    $loader.removeClass('hide');

    $.ajax({
      url: $form.prop('action'),
      method: $form.prop('method'),
      data: $form.serializeArray(),
    })
      .done((response) => {
        if (response?.success) {
          localStorage.setItem(submittedKey, 'true');
          showSuccess();
        } else {
          showError(response?.message ?? defaultError);
        }
      })
      .fail(() => showError(defaultError))
      .always(() => {
        loading = false;
        $loader.addClass('hide');
      });
  });
};

const initPopup = () => {
  $.magnificPopup.open({
    type: 'inline',
    fixedContentPos: true,
    closeOnBgClick: true,
    mainClass: 'mfp-marketing-email',
    items: {
      src: '#email-marketing-popup',
    },
    callbacks: {
      open() {
        $('.js-cancel').on('click', (e) => {
          e.preventDefault();
          $.magnificPopup.close();
        });
      },

      close() {
        if (!getHasSubmitted()) {
          setHasDeclinedEmail();
        }
      },
    }
  });
};

$(() => {
  if (getIsLoggedIn() || getHasDeclined() || getHasSubmitted()) {
    return;
  }

  initForm();
  initPopup();
});
